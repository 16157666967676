<template>
	<div class="d-sm-flex flex-column align-end calendar-close">
		<div v-on:click="value.is_show = false" class="close-calendar mb-2 d-flex align-center justify-center">
			<img :src="$store.state.icons.icons['RedCross']" alt />
		</div>
		<div class="my-calendar d-flex justify-center align-center flex-column">
			<v-date-picker light landscape="landscape" :min="min" @change="checkValid()" color="#2974FF" v-model="value.date"></v-date-picker>

			<div class="time-select">
				<p class="heading-sf16 mb-2">Requested delivery date & time</p>
				<p class="heading-mf16 mb-4">{{ this.value.requested_date }} / {{ timeOfDelivery }}</p>
				<div class="d-flex align-center">
					<div class="d-flex flex-column justify-center">
						<div class="d-flex align-start mb-1">
							<p class="heading-rf13">Start time</p>
						</div>
						<div class="d-flex align-center">
							<v-select
								@change="checkValid()"
								class="time-picker"
								:items="startTimes"
								label="Start time"
								height="44"
								hide-details
								dense
								light
								solo
								v-model="startTime"
							></v-select>
						</div>
					</div>

					<div class="d-flex flex-column justify-center ml-auto">
						<div class="d-flex align-start mb-1">
							<p class="heading-rf13">End time</p>
						</div>
						<div class="d-flex align-center">
							<v-select
								@change="checkValid()"
								class="time-picker"
								:items="endTimes"
								label="End time"
								height="44"
								hide-details
								dense
								light
								solo
								v-model="endTime"
							></v-select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	props: {
		value: Object,
	},

	data() {
		return {
			item: '',
			start_time: '',
			end_time: '',
			times: [
				'05:00 AM',
				'06:00 AM',
				'07:00 AM',
				'08:00 AM',
				'09:00 AM',
				'10:00 AM',
				'11:00 AM',
				'12:00 PM',
				'01:00 PM',
				'02:00 PM',
				'03:00 PM',
				'04:00 PM',
				'05:00 PM',
				'06:00 PM',
				'07:00 PM',
				'08:00 PM',
				'09:00 PM',
			],
		}
	},

	computed: {
		startTime: {
			get() {
				return moment(this.value.start_time, ['HH:mm:ss']).format('hh:mm A')
			},
			set(modifiedValue) {
				let newValue = moment(modifiedValue, ['hh:mm A']).format('HH:mm:ss')
				this.value.start_time = newValue
				if (this.value.start_time > this.value.end_time) {
					this.value.end_time = ''
				}
			},
		},

		endTime: {
			get() {
				return moment(this.value.end_time, ['HH:mm:ss']).format('hh:mm A')
			},
			set(modifiedValue) {
				let newValue = moment(modifiedValue, ['hh:mm A']).format('HH:mm:ss')
				this.value.end_time = newValue
			},
		},
		min() {
			return new Date().toISOString().slice(0, 10)
		},

		startTimes() {
			return this.times.filter((time) => {
				return moment(time, ['hh:mm A']).format('HH:mm:ss') < '21:00:00'
			})
		},

		endTimes() {
			// this.value.start_time = moment(this.value.start_time, ["hh:mm A"]).format("HH:mm:ss");
			return this.times.filter((time) => {
				return moment(time, ['hh:mm A']).format('HH:mm:ss') > this.value.start_time
			})
		},

		timeOfDelivery() {
			let time = this.value.requested_time

			if (time === 'M') {
				return 'Morning'
			} else if (time === 'N') {
				return 'Noon'
			} else if (time === 'A') {
				return 'Afternoon'
			} else if (time === 'L') {
				return 'Last Stop'
			} else if (time === 'AS') {
				return 'As soon as possible'
			}
		},
	},

	methods: {
		checkValid() {
			if (this.value.start_time != '' && this.value.end_time != '') {
				if (this.value.start_time >= this.value.end_time) {
					this.value.end_time = ''
					this.value.is_validate = false
				} else {
					if (this.value.end_time > '17:00:00') {
						this.value.time = 'L'
					} else if (this.value.end_time >= '12:00:00') {
						this.value.time = 'A'
					} else if (this.value.end_time >= '10:00:00') {
						this.value.time = 'N'
					} else if (this.value.end_time >= '05:00:00') {
						this.value.time = 'M'
					}
				}

				if (this.value.date === '' || this.value.time === '') {
					this.value.is_validate = false
				} else {
					this.value.is_validate = true
					this.value.is_show = false
				}
			}
		},
	},

	beforeDestroy() {
		this.checkValid()
	},
}
</script>

<style lang="scss">
.my-calendar {
  background: #ffffff;
  box-shadow: 0 22px 54px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px !important;
  height: auto;
  width: 360px;
  z-index: 9;

  .v-picker__title,
  .v-picker__title--landscape {
    display: none;
  }

  .v-card {
    box-shadow: 0px 0px 0px !important;
    border-radius: 8px !important;
  }
  .v-picker__body {
    margin: 0px 0px 0px 0px !important;
    width: 312px !important;
    padding: 24px 0px 0px 0px;
  }

  .v-btn__content {
    font-family: Inter-Regular !important;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .v-btn:before {
    background: #ffffff !important;
    border: none;
    border-radius: 4px !important;
  }

  .v-btn--rounded {
    border-radius: 4px !important;
  }

  .v-btn--active {
    background: rgba(41, 116, 255, 0.05) !important;
    border: 2px solid #2974ff !important;
    border-radius: 4px !important;
  }

  .v-btn--disabled {
    .v-btn__content {
      color: #a3a3a3 !important;
    }
  }

  .v-date-picker-table > table > thead > tr > th {
    font-family: Inter-Regular;
    font-size: 14px;
    color: #55647b;
    text-align: center;
    padding: 0px 0px 16px 0px;
  }

  .v-btn {
    height: 36px !important;
    width: 36px !important;
  }

  .v-date-picker-header__value,
  .v-btn--icon {
    color: #414141 !important;
    font-family: Inter-SemiBold;
    font-size: 18px;
  }

  .v-date-picker-table,
  .v-date-picker-header {
    padding: 0px;
    padding-bottom: 16px;
    height: auto;
  }

  .v-date-picker-header__value button:hover {
    color: #414141 !important;
  }

  .time-select {
    border-top: 1px solid #ebeef6;
    // height: 300px;
    width: 360px;
    padding: 24px;
  }

  .time-box {
    background: #ffffff;
    border: 2px solid #dae6f7;
    border-radius: 4px;
    height: 48px;
    width: 148px;
    font-family: Inter-SemiBold;
    font-size: 16px;
    color: #212121;
    cursor: pointer;
  }

  .time-selected {
    border: 2px solid #2974ff;
  }

  .time-picker {
    border: 2px solid #dae6f7;
    max-width: 148px;
    min-width: 148px;
    margin: 0px !important;
  }

  .time-picker:hover {
    border: 2px solid #2974ff !important;
  }

  .time-picker label {
    background: #ffffff !important;
    font-family: Inter-SemiBold !important;
    font-size: 16px !important;
    color: #212121 !important;
    left: auto !important;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none !important;
  }

  .v-text-field .v-input__prepend-inner,
  .v-text-field .v-input__append-inner {
    display: none !important;
  }

  .v-select__slot,
  .v-select__selections {
    text-align: center !important;
    justify-content: center !important;
    background: #ffffff !important;
    font-family: Inter-SemiBold !important;
    font-size: 16px !important;
    color: #212121 !important;
    left: auto !important;
  }

  .v-select__selections > input {
    display: none !important;
  }
}

    .v-date-picker-table {
    	height: 270px;
    }

    .close-calendar {
    	background: #ffffff;
    	box-shadow: 0 18px 50px 0 rgba(0, 0, 0, 0.2);
    	border-radius: 8px;
    	height: 46px;
    	width: 46px;
    	cursor: pointer;

    	img {
    		height: 16px;
    		filter: brightness(0) saturate(100%) invert(17%) sepia(1%) saturate(2954%) hue-rotate(9deg) brightness(107%) contrast(80%);
    	}
    }
</style>